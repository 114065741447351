const {
  getActivityState,
  getDaysSinceSyncActivity,
  getPlan,
  setActivityState,
  setDaysSinceSyncActivity,
  setPlan
} = require('../../util/user_state.js');
const UrlService = require('../../util/url_service.js').default;

const SPECTRUM_BREAKPOINT_SMALL_WIDTH = 768;

/**
 * @ngInject
 */
function HeaderController($scope, $window, DataService, NewrelicService) {
  var self = this;
  self.$scope = $scope;
  self.$window = $window;

  self.DataService = DataService;
  self.NewrelicService = NewrelicService;

  self.$window.addEventListener('resize', function() {
    self._handleWindowResize();
  });

  self._preloadData().then(function() {
    self._setNewrelicCustomAttributes();
  });
}

/**
 * Toggle the expanded state of the header.
 * (This is only relevant when the header is in a state where the navigation
 * links have been collapsed).
 */
HeaderController.prototype.handleNavigationToggleButton = function() {
  this.isNavigationExpanded = !this.isNavigationExpanded;
};

/**
 * If the navigation menu is already expanded, it should close when the window
 * expands to the size where there is no longer an option to display the menu.
 */
HeaderController.prototype._handleWindowResize = function() {
  if (this.$window.innerWidth >= SPECTRUM_BREAKPOINT_SMALL_WIDTH) {
    this.isNavigationExpanded = false;
    this.$scope.$digest();
  }
};

/**
 * Preload data using the DataService.
 *
 * @returns {Promise}
 * @private
 */
HeaderController.prototype._preloadData = function() {
  var self = this;
  return self.DataService.get('/user_info').then(function(userInfo) {
    setActivityState(userInfo.activity_state);
    setDaysSinceSyncActivity(userInfo.days_since_sync_activity);
    setPlan(userInfo.plan);
  });
};

/**
 * Send custom attributes regarding user info and referrer to NewRelic.
 *
 * @private
 */
HeaderController.prototype._setNewrelicCustomAttributes = function() {
  var referrer = UrlService.getSearchParam('referrer');
  if (referrer) {
    this.NewrelicService.setCustomAttribute('referrer', referrer);
  }
  this.NewrelicService.setCustomAttribute('activityState', getActivityState());
  this.NewrelicService.setCustomAttribute('daysSinceSyncActivity', getDaysSinceSyncActivity());
  this.NewrelicService.setCustomAttribute('plan', getPlan());
  this.NewrelicService.setCustomAttribute('referrerHeader', this.NewrelicService.getReferrerHeader());
};

module.exports = HeaderController;
